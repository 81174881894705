import { Injectable } from '@angular/core';
import { ServiceAbstract } from '../service.abstract';
import { NavigationReadableRepository } from './navigation.readable.repository';
import { NavigationListByTypeResponse, ResponseStatus } from '@infrastructure/message';
import { NavigationType } from '@infrastructure/xhelper';
import { NavigationBO } from '@core/domain';

@Injectable({ providedIn: 'root' })
export class NavigationReadableService extends ServiceAbstract<NavigationReadableRepository> {

  async findByType(type: NavigationType): Promise<NavigationListByTypeResponse> {
    // const body = { type };
    // const response = await this.repository.findByType(`${this._endPoint}/navigation/find-by-type`, body);
    // return response;

    // TODO: REMOVE SAMPLE DATA
    return Promise.resolve({
      status: ResponseStatus.SUCCESS,
      navigationListByTypeResponse: {
        navigationListByTypeItems: NAVIGATION_ACTIVE_SAMPLE,
        totalItems: 0,
        totalPage: 0,
      },
      transform: (data?: any) => data,
    });
  }

  override repositoryType(): any {
    return NavigationReadableRepository;
  }
}

export const NAVIGATION_ACTIVE_SAMPLE: NavigationBO[] = [
  {
    "id": 81,
    "name": "Navigation",
    "type": 0,
    "active": 1,
    "isDivider": false,
    "isHeader": true,
    "createdAt": "2024-05-22T10:35:56.000Z",
    "children": []
  },
  {
    "id": 82,
    "name": "Dashboard",
    "path": "/dashboard",
    "icon": "bi bi-cpu",
    "type": 0,
    "active": 1,
    "isDivider": false,
    "isHeader": false,
    "createdAt": "2024-05-22T10:35:56.000Z",
    "children": []
  },
  {
    "id": 84,
    "isDivider": true
  },
  {
    "id": 83,
    "name": "Activated Module",
    "path": "/activated-module",
    "icon": "bi bi-columns-gap",
    "type": 0,
    "active": 1,
    "isDivider": false,
    "isHeader": false,
    "createdAt": "2024-05-22T10:35:56.000Z",
    "children": [
      {
        "id": 85,
        "name": "Sample Child",
        "path": "/",
        "icon": "bi bi-pie-chart",
        "type": 0,
        "active": 1,
        "isDivider": false,
        "isHeader": false,
        "createdAt": "2024-05-23T02:31:11.000Z",
        "children": [
          {
            "id": 88,
            "name": "Sample Child 6",
            "path": "/",
            "icon": "bi bi-pie-chart",
            "type": 0,
            "active": 1,
            "isDivider": false,
            "isHeader": false,
            "createdAt": "2024-05-23T02:31:11.000Z"
          },
          {
            "id": 86,
            "name": "Sample Child 2",
            "path": "/",
            "icon": "bi bi-pie-chart",
            "type": 0,
            "active": 1,
            "isDivider": false,
            "isHeader": false,
            "createdAt": "2024-05-23T02:31:11.000Z",
            "children": [
              {
                "id": 87,
                "name": "Sample Child 3",
                "path": "/",
                "icon": "bi bi-pie-chart",
                "type": 0,
                "active": 1,
                "isDivider": false,
                "isHeader": false,
                "createdAt": "2024-05-23T02:31:11.000Z",
                "children": [
                  {
                    "id": 88,
                    "name": "Sample Child 4",
                    "path": "/",
                    "icon": "bi bi-pie-chart",
                    "type": 0,
                    "active": 1,
                    "isDivider": false,
                    "isHeader": false,
                    "createdAt": "2024-05-23T02:31:11.000Z"
                  }
                ]
              }
            ]
          },
          {
            "id": 88,
            "name": "Sample Child 5",
            "path": "/",
            "icon": "bi bi-pie-chart",
            "type": 0,
            "active": 1,
            "isDivider": false,
            "isHeader": false,
            "createdAt": "2024-05-23T02:31:11.000Z"
          }
        ]
      }
    ]
  },
  {
    "id": 84,
    "name": "Job Scheduler",
    "path": "/x/job-scheduler",
    "icon": "bi bi-pie-chart",
    "type": 0,
    "active": 1,
    "isDivider": false,
    "isHeader": false,
    "createdAt": "2024-05-22T10:35:56.000Z",
    "children": []
  }
]
