import { Directive, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Directive()
export abstract class DialogBase<T> {
  /**
   * Constructor
   * @param dialogRef -> MatDialogRef
   * @param data -> any
   */
  constructor(private dialogRef: MatDialogRef<T>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {}

  /**
   * Close modal (dialog)
   */
  close(): void {
    this.dialogRef?.close();
  }
}
