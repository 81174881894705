import { Injectable } from '@angular/core';
import { RepositoryBase } from '../repository.base';

@Injectable({ providedIn: 'root' })
export class WeatherRepository extends RepositoryBase {
  private wAPIKey: string = '';

  set weatherAPIKey(key: string) {
    this.wAPIKey = key;
  }

  findWeatherInfo<T>(endPoint: string, lat: number, long: number, days: number, lang: string): Promise<T> {
    return new Promise<T>((resolve) => {
      return this.get<any>(`${endPoint}/forecast.json?key=${this.wAPIKey}&q=${lat},${long}&days=${days}&lang=${lang}`).subscribe((data: any) => {
        resolve(data);
      });
    });
  }
}
