import { inject } from '@angular/core';
import { RepositoryBase } from './repository.base';

export abstract class ServiceAbstract<R extends RepositoryBase> {
  protected _endPoint: string = '';
  protected _repository: R;

  constructor() {
    this._repository = inject(this.repositoryType());
  }

  /**
   * Get the repository to handle more
   */
  get repository(): R {
    return this._repository;
  }

  set endPoint(endPoint: string) {
    this._endPoint = endPoint;
  }

  get endPoint() {
    return this._endPoint;
  }

  abstract repositoryType<R>(): R;
}
