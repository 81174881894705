import { Injectable } from '@angular/core';
import { ReadableRepository } from '../readable.repository';
import { RefreshTokenRequest, RefreshTokenResponse, SignInRequest, SignInResponse } from '@infrastructure/message';
import { SignInBO } from '@core/domain';

@Injectable({ providedIn: 'root' })
export class SignInReadableRepository extends ReadableRepository {
  /**
   *
   * @param userName -> user name
   * @param password -> password
   * @returns -> success = true; unsucces = false
   */
  signIn(endPoint: string, body: SignInRequest): Promise<SignInBO> {
    return this.findDataV2<SignInBO>(endPoint, SignInRequest, SignInResponse, body);
  }

  refreshToken(endPoint: string, body: RefreshTokenRequest): Promise<RefreshTokenResponse> {
    return this.findData<RefreshTokenResponse>(endPoint, RefreshTokenResponse, body);
  }
}
