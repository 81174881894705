import { DialogType } from './dialog.type';

/**
 * Dialog option
 */
export abstract class DialogOption<T = DialogType, D = any> {
  cfn?: any; /** call back function */
  data?: D; /** dynamic data */
  type?: T; /** type of dialog */
  dynamicComponent?: any; /** dynamic component */
}
