export class Constant {
  public static API_URL = 'API_URL';
  public static ACCESS_TOKEN = 'atocell';
  public static REFRESH_TOKEN = 'rtocell';
  public static AID = 'aidcell';
  public static UID = 'uidcell';
  public static CURRENT_LANGUAGE = 'lang';
  public static SCHEDULER_PAGE_TYPE_CLIENT = 1;
  public static SCHEDULER_PAGE_TYPE_CRONJOB = 2;
  public static APP_MODE = 'appMode';
  public static ENVIRONMENT = 'ENVIRONMENT';
  public static APP_THEME_SERVICE = 'APP_THEME_SERVICE';
  public static APP_SETTING_SERVICE = 'APP_SETTING_SERVICE';
  public static APP_VARIABLES_SERVICE = 'APP_VARIABLES_SERVICE';
}
