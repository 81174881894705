import { Injectable } from '@angular/core';
import { ReadableRepository } from '../readable.repository';
import { NotificationListRequest, NotificationListResponse } from '@infrastructure/message';
import { NotificationPagingBO } from '@core/domain';

@Injectable({ providedIn: 'root' })
export class NotificationReadableRepository extends ReadableRepository {
  findNotificationList(endPoint: string, body: NotificationListRequest): Promise<NotificationPagingBO> {
    return this.findDataV2<NotificationPagingBO>(endPoint, NotificationListRequest, NotificationListResponse, body);
  }
}
