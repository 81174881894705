import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { BusinessCardListResponse } from '@infrastructure/message';
import { ServiceAbstract } from '../service.abstract';
import { BusinessCardReadableRepository } from './business-card.readable.repository';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class BusinessCardReadableService extends ServiceAbstract<BusinessCardReadableRepository> {
  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  async findBusinessCardList(page: number, limit: number, sort: string, sortBy: SortDirection, name: string, jobTitle: string): Promise<BusinessCardListResponse> {
    const response = await this.repository.findBusinessCardList(`${this._endPoint}/business-card/find-list`, page, limit, sort, sortBy, name, jobTitle);
    return response;
  }

  async sanitizedUrl(url: string): Promise<SafeUrl> {
    const response = await this.repository.fetchFile(url);
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(response));
  }

  override repositoryType(): any {
    return BusinessCardReadableRepository;
  }
}
