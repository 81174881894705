import { HttpClient } from '@angular/common/http';
import { inject, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const REPOSITORY = new InjectionToken<RepositoryBase>('RepositoryBase');

export abstract class RepositoryBase {
  endPoint = '';

  /** HTTP Client to connect to the gateway API */
  protected httpClient!: HttpClient;

  /** Default timezone of the client system */
  protected requestOptions = {
    headers: {
      'Time-Zone': `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
      //Authorization: "Bearer " + localStorage.getItem("ACCESS-TOKEN"),
    },
  };

  constructor() {
    /**
     * Set the DI of httpClient
     * This type of inject is only support from Angular 14+
     */
    this.httpClient = inject(HttpClient);
  }

  /**
   * GET
   * @param endPoint
   */
  public get<T>(endPoint: string): Observable<T> {
    return this.httpClient.get<T>(endPoint, this.requestOptions);
  }

  /**
   * POST
   */
  public post<T>(endPoint: string, body: Object): Observable<T> {
    return this.httpClient.post<T>(endPoint, body, this.requestOptions);
  }

  /**
   * PUT
   */
  public put<T>(endPoint: string, body: Object): Observable<T> {
    return this.httpClient.put<T>(endPoint, body, this.requestOptions);
  }

  /**
   * DELETE
   */
  public delete<T>(endPoint: string): Observable<T> {
    return this.httpClient.delete<T>(endPoint, this.requestOptions);
  }

  /**
   * User for Unit test
   * @param httpClient
   */
  public setHttpClient(httpClient: HttpClient): void {
    this.httpClient = httpClient;
  }
}
