import { propertyMap } from '@infrastructure/xhelper';
import { XResponse } from '../x.response';
import { RefreshTokenBO } from '@core/domain';

export class RefreshTokenResponse extends XResponse {
  @propertyMap('data', RefreshTokenBO)
  refreshTokenResponse: RefreshTokenBO = new RefreshTokenBO();

  override transform(data?: any): any {
    return undefined;
  }
}
