import { propertyMap } from '@infrastructure/xhelper';
import { BOBase } from '../bo.base';
import { ClientCronjobBO } from './client-cronjob.bo';

export class SchedulerByCronjobBO extends BOBase {
  @propertyMap('id', Number)
  id: number = 0;

  @propertyMap('client', ClientCronjobBO)
  client: ClientCronjobBO = new ClientCronjobBO();

}
