import { Injectable } from '@angular/core';
import { BusinessCardListRequest, BusinessCardListResponse, ResponseStatus } from '@infrastructure/message';
import { ReadableRepository } from '../readable.repository';
import { SortDirection } from '@angular/material/sort';

@Injectable({ providedIn: 'root' })
export class BusinessCardReadableRepository extends ReadableRepository {
  findBusinessCardList(endPoint: string, page: number, limit: number, sort: string, sortBy: SortDirection, name: string, jobTitle: string): Promise<BusinessCardListResponse> {
    const body = {
      page: page,
      limit: limit,
      sort: sort,
      sort_by: sortBy,
      name: name,
      job_title: jobTitle
    }
    return this.findData<BusinessCardListResponse>(endPoint, BusinessCardListResponse, body);
  }
}
