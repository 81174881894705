import { propertyMap } from '@infrastructure/xhelper';
import { BOBase } from '../bo.base';
import { CronjobClientBO } from './cronjob-client.bo';

export class SchedulerByClientBO extends BOBase {
  @propertyMap('id', Number)
  id: number = 0;

  @propertyMap('cronjob', CronjobClientBO)
  cronjob: CronjobClientBO = new CronjobClientBO();

}
