import { Injectable } from '@angular/core';
import { ServiceAbstract } from '../service.abstract';
import { SortDirection } from '@angular/material/sort';
import { ModuleBO, ModulePagingBO } from '@core/domain';
import { ModuleListRequest, ResponseStatus } from '@infrastructure/message';
import { ModuleReadableRepository } from './module.readable.repository';

@Injectable({ providedIn: 'root' })
export class ModuleReadableService extends ServiceAbstract<ModuleReadableRepository> {
  async findModuleList(page: number, limit: number, sort: string, sortBy: SortDirection): Promise<ModulePagingBO> {
    const body: ModuleListRequest = {
      page,
      limit,
      sort,
      sortBy,
    };

    // const response = await this.repository.findModuleList(`${this._endPoint}/module/find-list`, body);

    // return response;

    // TODO: REMOVE SAMPLE DATA
    return Promise.resolve({
      status: ResponseStatus.SUCCESS,
      modules: MODULE_SAMPLE,
      totalItems: 0,
      totalPage: 0,
      transform: (data?: any) => data,
    });
  }

  override repositoryType(): any {
    return ModuleReadableRepository;
  }
}

export const MODULE_SAMPLE: ModuleBO[] = [
  {
    id: 1,
    icon: 'assets/img/apps/app-store.png',
    title: 'XAPP',
    description: 'XAPP Description',
    url: 'x'
  },
  {
    id: 2,
    icon: 'assets/img/apps/apple.png',
    title: 'Business Card',
    description: 'Business Card Description',
    url: 'x-business-card'
  },
  {
    id: 3,
    icon: 'assets/img/apps/blog.png',
    title: 'NG WORK',
    description: 'Description XAPP',
    url: 'x-ng-work'
  },
  {
    id: 4,
    icon: 'assets/img/apps/app-store.png',
    title: 'XAPP',
    description: 'XAPP Description',
    url: 'x'
  },
  {
    id: 5,
    icon: 'assets/img/apps/apple.png',
    title: 'Business Card',
    description: 'Business Card Description',
    url: 'x-business-card'
  },
  {
    id: 6,
    icon: 'assets/img/apps/blog.png',
    title: 'NG WORK',
    description: 'Description XAPP',
    url: 'x-ng-work'
  },
  {
    id: 7,
    icon: 'assets/img/apps/app-store.png',
    title: 'XAPP',
    description: 'XAPP Description',
    url: 'x'
  },
  {
    id: 8,
    icon: 'assets/img/apps/apple.png',
    title: 'Business Card',
    description: 'Business Card Description',
    url: 'x-business-card'
  },
  {
    id: 9,
    icon: 'assets/img/apps/blog.png',
    title: 'NG WORK',
    description: 'Description XAPP',
    url: 'x-ng-work'
  },
  {
    id: 10,
    icon: 'assets/img/apps/app-store.png',
    title: 'XAPP',
    description: 'XAPP Description',
    url: 'x'
  },
  {
    id: 11,
    icon: 'assets/img/apps/apple.png',
    title: 'Business Card',
    description: 'Business Card Description',
    url: 'x-business-card'
  },
  {
    id: 12,
    icon: 'assets/img/apps/blog.png',
    title: 'NG WORK',
    description: 'Description XAPP',
    url: 'x-ng-work'
  },
  {
    id: 13,
    icon: 'assets/img/apps/app-store.png',
    title: 'XAPP',
    description: 'XAPP Description',
    url: 'x'
  },
  {
    id: 14,
    icon: 'assets/img/apps/apple.png',
    title: 'Business Card',
    description: 'Business Card Description',
    url: 'x-business-card'
  },
  {
    id: 15,
    icon: 'assets/img/apps/blog.png',
    title: 'NG WORK',
    description: 'Description XAPP',
    url: 'x-ng-work'
  },
]
