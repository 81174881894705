import { Component, Inject, OnInit, PLATFORM_ID, forwardRef } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Context, ServiceAbstract, WeatherService } from '@core/application';
import { Constant, XMHelper } from '@infrastructure/xhelper';
import { TranslateService } from '@ngx-translate/core';
import { ViewBase } from '../view.base';
import moment from 'moment';

@Component({
  selector: 'weather-widget',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './weather.widget.component.html',
  styleUrl: './weather.widget.component.scss',
  providers: [
    Context,
    {
      provide: ServiceAbstract,
      useExisting: forwardRef(() => WeatherService),
    },
  ],
})
export class WeatherWidget extends ViewBase implements OnInit {
  public weather: any;
  public isHiddenDailyWeather = true;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, @Inject(Constant.ENVIRONMENT) private environment: any, private translate: TranslateService) {
    super();
    this.context.setEndPoint(this.environment.WEATHER_API_ENDPOINT);
    this.context.service.weatherAPIKey = this.environment.WEATHER_API_KEY;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getPosition().then((pos) => {
        this.context.service.findWeatherInfo(pos.lat, pos.lng, this.environment.WEATHER_API_DEFAUL_DAYS, this.context.getItemStorage(Constant.CURRENT_LANGUAGE)).then((res: any) => {
          this.weather = res;
        });
      });
    }
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getWeatherIcon(code: number): string {
    let weatherIcon: string;

    switch (code) {
      case 1000:
        weatherIcon = '/assets/img/weather/sun.svg';
        break;
      case 1003:
      case 1006:
      case 1009:
      case 1030:
      case 1135:
      case 1147:
        weatherIcon = '/assets/img/weather/cloudy.svg';
        break;
      case 1150:
      case 1153:
      case 1189:
      case 1240:
      case 1249:
      case 1252:
      case 1261:
        weatherIcon = '/assets/img/weather/light-rain.svg';
        break;
      case 1063:
      case 1180:
      case 1183:
      case 1186:
      case 1198:
      case 1204:
      case 1210:
      case 1216:
      case 1222:
      case 1225:
      case 1243:
      case 1246:
      case 1255:
        weatherIcon = '/assets/img/weather/moderate-rain.svg';
        break;
      case 1192:
      case 1195:
      case 1276:
      case 1279:
      case 1282:
      case 1258:
        weatherIcon = '/assets/img/weather/heavy-rain.svg';
        break;
      case 1087:
      case 1114:
      case 1117:
      case 1213:
      case 1219:
      case 1237:
      case 1273:
        weatherIcon = '/assets/img/weather/thunder.svg';
        break;
      default:
        weatherIcon = '/assets/img/weather/not-available.svg';
        break;
    }

    return weatherIcon;
  }

  getDayOfWeeks(date: any) {
    if (XMHelper.isToDay(date)) {
      return this.translate.instant('common.day.today');
    }
    const dayOfWeek = XMHelper.getDayOfWeeks(date).toLowerCase();
    return this.translate.instant(`common.day.${dayOfWeek}`);
  }

  override getCtxServiceType() {
    return WeatherService;
  }
}
