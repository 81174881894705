import { propertyMap } from '@infrastructure/xhelper';
import { BOBase } from '../bo.base';

export class NotificationBO extends BOBase {
  @propertyMap('id', Number)
  id: number = 0;

  @propertyMap('isread', Boolean)
  isread: boolean = false;

  @propertyMap('isread_inline', Boolean)
  isreadInline: boolean = false;

  @propertyMap('date', Date)
  date: Date = new Date();

  @propertyMap('description', String)
  description: string = '';

  @propertyMap('fromuserid', Number)
  fromUserId: number = 0;

  @propertyMap('fromclientid', Number)
  fromClientId: number = 0;

  @propertyMap('from_fullname', Number)
  fromFullname: number = 0;

  @propertyMap('touserid', Number)
  toUserId: number = 0;

  @propertyMap('fromcompany', Number)
  fromCompany: number = 0;

  @propertyMap('link', String)
  link: string = '';

  @propertyMap('additional_data', String)
  additionalData: string[] = [];

}
