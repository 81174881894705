import { propertyMap } from '@infrastructure/xhelper';
import { XRequest } from '../x.request';

export class SignInRequest extends XRequest {
  @propertyMap('email')
  email: string = '';

  @propertyMap('password')
  password: string = '';

  @propertyMap('app_name')
  appName: string = '';

  @propertyMap('is_remember')
  isRemember: boolean = false;
}
