import { Component } from '@angular/core';

@Component({
  selector: 'lib-message',
  standalone: true,
  imports: [],
  template: `
    <p>
      message works!
    </p>
  `,
  styles: ``
})
export class MessageComponent {

}
