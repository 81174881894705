<ul class="breadcrumb" *ngIf="breadcrumbs.length > 0">
  <li *ngFor="let breadcrumb of breadcrumbs" class="breadcrumb-item">
    <a href="{{ breadcrumb.url }}">
      {{ breadcrumb.name }}
    </a>
  </li>
</ul>

<h1 class="page-header" *ngIf="title">
  {{ title }}
</h1>

<ng-content #content></ng-content>
