import { Component } from '@angular/core';

@Component({
  selector: 'cell-dialog-standard',
  standalone: true,
  imports: [],
  templateUrl: './dialog-standard.component.html',
  styleUrl: './dialog-standard.component.scss',
})
export class DialogStandardComponent {}
