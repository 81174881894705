import { Injectable } from '@angular/core';
import { ReadableRepository } from '../readable.repository';
import { ModuleListRequest, ModuleListResponse } from '@infrastructure/message';
import { ModulePagingBO } from '@core/domain';

@Injectable({ providedIn: 'root' })
export class ModuleReadableRepository extends ReadableRepository {
  findModuleList(endPoint: string, body: ModuleListRequest): Promise<ModulePagingBO> {
    return this.findDataV2<ModulePagingBO>(endPoint, ModuleListRequest, ModuleListResponse, body);
  }
}
