import { ChangeDetectorRef, Component, ComponentRef, Inject, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';

@Component({
  selector: 'cell-dialog-empty',
  standalone: true,
  imports: [CdkDrag, CdkDragHandle],
  templateUrl: './dialog-empty.component.html',
  styleUrl: './dialog-empty.component.scss',
})
export class DialogEmptyComponent implements OnInit {
  /**
   * Retrieve the dynamic content via DI of dialog content
   */
  @ViewChild('containerRef', { read: ViewContainerRef, static: true })
  container!: ViewContainerRef;

  /**
   * Component is a dialog component
   * which will display dynamic in runtime.
   */
  componentRef!: ComponentRef<any>;

  constructor(private dialogRef: MatDialogRef<DialogEmptyComponent>, private cdref: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) public data: { component: any; data: any }) {}

  ngOnInit(): void {
    this.render();
  }
  /**
   * Render the view(layout) dymamically.
   */
  protected render(): void {
    this.container.clear();
    this.componentRef = this.container.createComponent(this.data.component);
  }
}
