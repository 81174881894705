import { propertyMap } from "@infrastructure/xhelper";
import { XResponse } from "../x.response";
import { NavigationListByTypePagingResponse } from "./navigation-list-by-type-paging.response";

export class NavigationListByTypeResponse extends XResponse {
  @propertyMap('data', NavigationListByTypePagingResponse)
  navigationListByTypeResponse: NavigationListByTypePagingResponse = new NavigationListByTypePagingResponse();

  override transform(data?: any): any {
    return undefined;
  }

}
