import { Injectable } from '@angular/core';
import { ReadableRepository } from '../readable.repository';
import { NavigationListByTypeRequest, NavigationListByTypeResponse } from '@infrastructure/message';

@Injectable({ providedIn: 'root' })
export class NavigationReadableRepository extends ReadableRepository {

  findByType(endPoint: string, body: NavigationListByTypeRequest): Promise<NavigationListByTypeResponse> {
    return this.findData<NavigationListByTypeResponse>(endPoint, NavigationListByTypeResponse, body);
  }

}
