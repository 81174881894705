import { Injectable } from '@angular/core';
import { ReadableRepository } from '../readable.repository';
import { SchedulerByClientListRequest, SchedulerByClientListResponse } from '@infrastructure/message';

@Injectable({ providedIn: 'root' })
export class SchedulerByClientReadableRepository extends ReadableRepository {

  findSchedulerClients(endPoint: string, body: SchedulerByClientListRequest): Promise<SchedulerByClientListResponse> {
    return this.findData<SchedulerByClientListResponse>(endPoint, SchedulerByClientListResponse, body);
  }

}
