import { propertyMap } from '@infrastructure/xhelper';
import { XResponse } from '../x.response';
import { SignInBO } from '@core/domain';
import { jwtDecode } from 'jwt-decode';
import { ResponseStatus } from '../response-status.enum';

export class SignInResponse extends XResponse {
  @propertyMap('data', SignInBO)
  signInResponse: SignInBO = new SignInBO();

  override transform(data?: any): any {
    if (this.status == ResponseStatus.SUCCESS) {
      let jwtDecoded: any = jwtDecode(this.signInResponse.accessToken);

      this.signInResponse.aid = jwtDecoded?.app_name;
      this.signInResponse.uid = jwtDecoded?.staff_id;
    }

    return this.signInResponse;
  }
}
