import { propertyMap } from '@infrastructure/xhelper';
import { BOBase } from './bo.base';

export class CronTimeBO extends BOBase {
  @propertyMap('second', String)
  second: string = '';

  @propertyMap('minute', String)
  minute: string = '';

  @propertyMap('hour', String)
  hour: string = '';

  @propertyMap('day', String)
  day: string = '';

  @propertyMap('month', String)
  month: string = '';

  @propertyMap('weekday', String)
  weekday: string = '';
}
