import { propertyMap } from '@infrastructure/xhelper';
import { BOBase } from './bo.base';

export class SignInBO extends BOBase {
  @propertyMap('ato', String)
  accessToken: string = '';

  @propertyMap('rto', String)
  refreshToken?: string = '';

  aid?: string = '';

  uid?: string = '';
}
