import { NgFor, NgIf } from '@angular/common';
import { Component, ContentChild, Inject, OnInit, Optional, SkipSelf } from '@angular/core';
import { BreadcrumbItem, ViewXContent } from '../view.content';
import { VIEW_CONTENT } from '../view.content.token';
import { MatDialogModule } from '@angular/material/dialog';
import { Constant } from '@infrastructure/xhelper';
@Component({
  selector: 'cell-view-standard',
  standalone: true,
  imports: [NgIf, NgFor, MatDialogModule],
  templateUrl: './view-standard.component.html',
  styleUrl: './view-standard.component.scss',
})
export class ViewStandardComponent implements OnInit {
  @ContentChild(VIEW_CONTENT as any, { static: true })
  content: ViewXContent | undefined;

  breadcrumbs: BreadcrumbItem[] = [];

  title?: string = 'View Standard';

  constructor(@Optional() @SkipSelf() public contentX: ViewXContent, @Inject(Constant.ENVIRONMENT) private environment: any) {
    this.contentX.setCxtEndPoint(this.environment.apiUrl);
  }

  ngOnInit(): void {
    if (this.contentX) {
      this.content = this.contentX;
    }
    this.title = this.content?.getTitle();
    this.breadcrumbs = this.content?.getBreadcrumb() == undefined ? [] : this.content.getBreadcrumb();
  }
}
