export class XFHelper {
  /**
   * Create an instance of generic type
   * @param type
   * @returns T type
   */
  public static createInstance<T>(type: new () => T): T {
    return new type();
  }

  /**
   * Get key of enum by value
   * @param enumType type of enum
   * @param value value need to find key
   * @returns key of enum
   */
  public static getEnumKeyByValue<T extends Record<any, unknown>>(enumType: T, value: any) {
    return Object.keys(enumType).find(key => enumType[key] === value);
  }
}
