import { inject, Injectable } from '@angular/core';
import { WriteableRepository } from '../writeable.repository';

@Injectable({ providedIn: 'root' })
export class SignInWriteableRepository extends WriteableRepository {
  /**
   * Register new user
   * @param userName -> user name
   * @param password -> password
   * @returns -> success = token
   */
  register(userName: string, password: string): string {
    /** TODO */
    return 'token';
  }
}
