import { propertyMap } from '@infrastructure/xhelper';
import { XResponse } from '../x.response';
import { SchedulerByCronjobListPagingResponse } from './scheduler-by-cronjob-list-paging.response';

export class SchedulerByCronjobListResponse extends XResponse {
  @propertyMap('data', SchedulerByCronjobListPagingResponse)
  schedulerByCronjobListResponse: SchedulerByCronjobListPagingResponse = new SchedulerByCronjobListPagingResponse();

  override transform(data?: any): any {
    return undefined;
  }
}
