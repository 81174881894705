import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/** Injection of STORE */
export const BROWSER_STORAGE = new InjectionToken<Storage>('BROWSER-STORAGE', {
  providedIn: 'root',
  factory: () => localStorage,
});

interface ICache {
  [key: string]: BehaviorSubject<any>;
}
type serializable = object | Object;

@Injectable({
  providedIn: 'root',
})
export class BrowserStorageService {
  private cache: ICache;

  /** Constructor with storage service input */
  constructor(@Inject(BROWSER_STORAGE) public storage: any) {
    this.cache = Object.create(null);
  }

  /**
   * Get the item from the storage
   * @param key
   */
  public get(key: string) {
    return this.storage.getItem(key);
  }

  /**
   * Set the item to the storage
   * @param key
   * @param value
   */
  public set(key: string, value: string) {
    this.storage.setItem(key, value);
  }

  /**
   * Get the item from the storage
   * @param key
   */
  public getItem<T extends serializable>(key: string): BehaviorSubject<T> {
    if (this.cache[key]) return this.cache[key];
    else return (this.cache[key] = new BehaviorSubject(JSON.parse(this.storage.getItem(key))));
    //return this.storage.getItem(key);
  }

  /**
   * Set the item to the storage
   * @param key
   * @param value
   */
  public setItem<T extends serializable>(key: string, value: T): BehaviorSubject<T> {
    this.storage.setItem(key, JSON.stringify(value));
    if (this.cache[key]) {
      this.cache[key].next(value);
      return this.cache[key];
    }

    return (this.cache[key] = new BehaviorSubject(value));

    //return this.storage.setItem(key, value);
  }

  /**
   * Remove the item in the storage
   * @param key
   */
  public removeItem(key: string) {
    //this.storage.removeItem(key);
    this.storage.removeItem(key);
    if (this.cache[key]) this.cache[key].next(undefined);
  }

  /**
   * Clear all the item in the storage
   */
  public clear() {
    this.storage.clear();
  }
}
