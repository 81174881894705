import { Component } from '@angular/core';

@Component({
  selector: 'lib-application',
  standalone: true,
  imports: [],
  template: `
    <p>
      application works!
    </p>
  `,
  styles: ``
})
export class ApplicationComponent {

}
