import { Injectable } from '@angular/core';
import { ServiceAbstract } from '../service.abstract';
import { NotificationReadableRepository } from './notification.readable.repository';
import { SortDirection } from '@angular/material/sort';
import { NotificationPagingBO } from '@core/domain';
import { NotificationListRequest } from '@infrastructure/message';

@Injectable({ providedIn: 'root' })
export class NotificationReadableService extends ServiceAbstract<NotificationReadableRepository> {
  async findNotificationList(page: number, limit: number, sort: string, sortBy: SortDirection, toUserId: number[], fromUserId: number[]): Promise<NotificationPagingBO> {
    const body: NotificationListRequest = {
      page,
      limit,
      sort,
      sortBy,
      toUserId,
      fromUserId,
    };

    const response = await this.repository.findNotificationList(`${this._endPoint}/notification/find-list`, body);

    return response;
  }

  override repositoryType(): any {
    return NotificationReadableRepository;
  }
}
