import { propertyMap } from '@infrastructure/xhelper';
import { XResponse } from '../x.response';
import { ModulePagingBO } from '@core/domain';

export class ModuleListResponse extends XResponse {
  @propertyMap('data', ModulePagingBO)
  moduleListResponse: ModulePagingBO = new ModulePagingBO();

  override transform(data?: any): any {
    return this.moduleListResponse;
  }
}
