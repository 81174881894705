import { Injectable } from '@angular/core';
import { WeatherRepository } from './weather.repository';
import { ServiceAbstract } from '../service.abstract';

@Injectable({ providedIn: 'root' })
export class WeatherService extends ServiceAbstract<WeatherRepository> {
  async findWeatherInfo(lat: number, long: number, days: number, lang: string): Promise<any> {
    let response = await this.repository.findWeatherInfo(this._endPoint, lat, long, days, lang);
    return response;
  }

  set weatherAPIKey(key: string) {
    this.repository.weatherAPIKey = key;
  }

  override repositoryType(): any {
    return WeatherRepository;
  }
}
