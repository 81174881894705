import { SortDirection } from '@angular/material/sort';
import { XRequest } from './x.request';
import { propertyMap } from '@infrastructure/xhelper';

/**
 * Sort Request
 */
export class SortRequest extends XRequest {
  public sort: string | undefined;
  public sort_by: SortDirection | undefined;
}
