import { Component } from '@angular/core';

@Component({
  selector: 'lib-xhelper',
  standalone: true,
  imports: [],
  template: `
    <p>
      xhelper works!
    </p>
  `,
  styles: ``
})
export class XhelperComponent {

}
