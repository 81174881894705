import { Injectable } from '@angular/core';
import { ServiceAbstract } from '../service.abstract';
import { SchedulerByClientReadableRepository } from './scheduler-by-client.readable.repository';
import { SchedulerByClientListResponse } from '@infrastructure/message';
import { SortDirection } from '@angular/material/sort';

@Injectable({ providedIn: 'root' })
export class SchedulerByClientReadableService extends ServiceAbstract<SchedulerByClientReadableRepository> {

  async findSchedulerClients(page: number, limit: number, sort: string, sort_by: SortDirection): Promise<SchedulerByClientListResponse> {
    const body = { page, limit, sort, sort_by };
    const response = await this.repository.findSchedulerClients(`${this._endPoint}/client/find-list`, body);
    return response;
  }

  override repositoryType(): any {
    return SchedulerByClientReadableRepository;
  }
}
