import { propertyMap } from '@infrastructure/xhelper';
import { XResponse } from '../x.response';
import { BusinessCardListPagingResponse } from './business-card-list-paging.response';

export class BusinessCardListResponse extends XResponse {
    @propertyMap('data', BusinessCardListPagingResponse)
    businessCardListResponse: BusinessCardListPagingResponse = new BusinessCardListPagingResponse();

    override transform(data?: any): any {
        return undefined;
    }
}