import { SortDirection } from '@angular/material/sort';
import { XRequest } from './x.request';
import { propertyMap } from '@infrastructure/xhelper';

/**
 * Sort Request V2
 */
export class SortRequestV2 extends XRequest {
  @propertyMap('sort')
  public sort: string | undefined;

  @propertyMap('sort_by')
  public sortBy: SortDirection | undefined;
}
