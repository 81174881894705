import { propertyMap } from '@infrastructure/xhelper';
import { BOBase } from '../bo.base';
import { SchedulerByClientBO } from './scheduler-by-client.bo';

export class ClientBO extends BOBase {
  @propertyMap('id', Number)
  id: number = 0;

  @propertyMap('trial_time', Number)
  trialTime: number = 120;

  @propertyMap('first_name', String)
  firstName: string = '';

  @propertyMap('last_name', String)
  lastName: string = '';

  @propertyMap('phone_number', String)
  phoneNumber: string = '';

  @propertyMap('company_name', String)
  companyName: string = '';

  @propertyMap('email', String)
  email: string = '';

  @propertyMap('database_name', String)
  databaseName: string = '';

  @propertyMap('database_user', String)
  databaseUser: string = '';

  @propertyMap('app_name', String)
  appName: string = '';

  @propertyMap('host_address', String)
  hostAddress: string = '';

  @propertyMap('created_at', String)
  createdAt: string = '';

  @propertyMap('cronjob_client', SchedulerByClientBO)
  schedulerByClientBO?: Array<SchedulerByClientBO> = [];

}
