import { propertyMap } from '@infrastructure/xhelper';
import { BOBase } from '../bo.base';

export class ModuleBO extends BOBase {
  @propertyMap('id', Number)
  id: number = 0;

  @propertyMap('title', String)
  title: string = '';

  @propertyMap('description', String)
  description: string = '';

  @propertyMap('icon', String)
  icon: string = '';

  @propertyMap('url', String)
  url: string = '';
}
