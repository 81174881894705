<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">MODAL TITLE // TODO: SHOULD BE DYNAMIC ON</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
    </div>
    <div class="modal-body">
      <p>Modal body text goes here.</p>
      <ng-container #containerRef></ng-container>
    </div>
    <div class="modal-footer">
      <button mat-button type="button" class="btn btn-info mb-1 ms-1" (click)="close()">Close</button>
    </div>
  </div>
</div>
