import { Injectable, InjectionToken } from '@angular/core';
import { RepositoryBase } from './repository.base';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WriteableRepository extends RepositoryBase {
  constructor() {
    super();
  }

  /**
   * Save data
   * @param endPoint -> uri
   * @param body -> JSON body to save sdfdata
   */
  save<T>(endPoint: string, body: Object): Promise<T> {
    return lastValueFrom(this.post<T>(endPoint, body));
  }

  /**
   * Remove item
   * @param id -> id (1, 2, 3, ...)
   * @param endPoint  -> uri
   */
  remove<T>(id: number, endPoint: string): Promise<T> {
    return lastValueFrom(this.delete<T>(`${endPoint}/${id}`));
  }

  /**
   * Remove item by UUID
   * @param id -> id (1, 2, 3, ...)
   * @param endPoint  -> uri
   */
  removeByUUID<T>(uuid: string, endPoint: string): Promise<T> {
    return lastValueFrom(this.delete<T>(`${endPoint}/${uuid}`));
  }

  /**
   * Update item
   * @param endPoint
   * @param body
   */
  update<T>(endPoint: string, body: Object): Promise<T> {
    return lastValueFrom(this.put<T>(endPoint, body));
  }
}
