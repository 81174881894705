import { propertyMap } from '@infrastructure/xhelper';
import { XResponse } from '../x.response';
import { NotificationPagingBO } from '@core/domain';

export class NotificationListResponse extends XResponse {
  @propertyMap('data', NotificationPagingBO)
  notificationListResponse: NotificationPagingBO = new NotificationPagingBO();

  override transform(data?: any): any {
    this.notificationListResponse.notifications.map((notication) => {
      let result: any = {};
      notication.additionalData.forEach((item, index) => {
        result[`s${index}`] = item;
      });
      notication.additionalData = result;
    });
    return this.notificationListResponse;
  }
}
