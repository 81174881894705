import { Component } from '@angular/core';

@Component({
  selector: 'lib-domain',
  standalone: true,
  imports: [],
  template: `
    <p>
      domain works!
    </p>
  `,
  styles: ``
})
export class DomainComponent {

}
