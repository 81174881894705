import { propertyMap } from '@infrastructure/xhelper';
import { BOBase } from './bo.base';

export class BusinessCardBO extends BOBase {
    @propertyMap('id', Number)
    id: number = 0;

    @propertyMap('name', String)
    name: string = '';

    @propertyMap('type', Number)
    type: number = 0;

    @propertyMap('path', String)
    path: string = '';

    @propertyMap('image_url', String)
    imageUrl: string = '';

    @propertyMap('active', Number)
    active: number = 0;

    @propertyMap('created_by', String)
    createdBy: string = '';

    @propertyMap('created_at', String)
    createdAt: string = '';

    @propertyMap('work_phone', String)
    workPhone?: string[];

    @propertyMap('mobile_phone', String)
    mobilePhone?: string[];

    @propertyMap('job_title', String)
    jobTitle?: string;

    @propertyMap('fax', String)
    fax?: string[];

    @propertyMap('email', String)
    email?: string[];

    @propertyMap('contact_name', String)
    contactName?: string[];

    @propertyMap('company_name', String)
    companyName?: string[];

    @propertyMap('addresses', String)
    address?: string[];
}