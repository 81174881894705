import { Component, ContentChild, Inject, Optional, SkipSelf } from '@angular/core';
import { ViewXEContent } from '../view.content';
import { VIEW_CONTENT } from '../view.content.token';
import { Constant } from '@infrastructure/xhelper';

@Component({
  selector: 'cell-view-empty',
  standalone: true,
  imports: [],
  templateUrl: './view-empty.component.html',
  styleUrl: './view-empty.component.scss',
})
export class ViewEmptyComponent {
  @ContentChild(VIEW_CONTENT as any, { static: true })
  content: ViewXEContent | undefined;

  constructor(@Optional() @SkipSelf() public context: ViewXEContent, @Inject(Constant.ENVIRONMENT) private environment: any) {
    this.context.setCxtEndPoint(this.environment.apiUrl);
  }

  ngOnInit(): void {}
}
