import { propertyMap } from '@infrastructure/xhelper';
import { SortRequestV2 } from './sort-v2.request';

export class PagingRequestV2 extends SortRequestV2 {
  @propertyMap('page')
  public page?: number;

  @propertyMap('limit')
  public limit?: number;
}
