import { Injectable } from '@angular/core';
import { ServiceAbstract } from '../service.abstract';
import { HeaderReadableRepository } from './header.readable.repository';

@Injectable({ providedIn: 'root' })
export class HeaderReadableService extends ServiceAbstract<HeaderReadableRepository> {
  override repositoryType(): any {
    return HeaderReadableRepository;
  }
}
