import { Injectable } from '@angular/core';
import { ServiceAbstract } from '../service.abstract';
import { SchedulerByCronjobReadableRepository } from './scheduler-by-cronjob.readable.repository';
import { SortDirection } from '@angular/material/sort';
import { SchedulerByCronjobListResponse } from '@infrastructure/message';

@Injectable({ providedIn: 'root' })
export class SchedulerByCronjobReadableService extends ServiceAbstract<SchedulerByCronjobReadableRepository> {

  async findSchedulerCronjobs(page: number, limit: number, sort: string, sort_by: SortDirection): Promise<SchedulerByCronjobListResponse> {
    const body = { page, limit, sort, sort_by };
    const response = await this.repository.findSchedulerCronjobs(`${this._endPoint}/cronjob/find-list`, body);
    return response;
  }

  override repositoryType(): any {
    return SchedulerByCronjobReadableRepository;
  }
}
