import { propertyMap } from '@infrastructure/xhelper';
import { BOBase } from './bo.base';

export class PagingBaseBO extends BOBase {
  @propertyMap('total_page', Number)
  totalPage: number = 0;

  @propertyMap('total_items', Number)
  totalItems: number = 0;
}
