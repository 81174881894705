<div class="row">
  <div class="col-md-3 mb-3 mb-lg-0">
    <h5 class="text-center titleCrr">{{ weather?.location.name }}</h5>
    <div class="row">
      <div class="col-6 text-center">
        <img width="100px" src="{{ getWeatherIcon(weather?.current.condition.code) }}" alt="cloudy_icon" />
        <div>{{ weather?.current.condition.text }}</div>
      </div>
      <div class="col-6">
        <ul class="list-unstyled">
          <li class="h2">{{ weather?.current.temp_c }}°C</li>
          <li class="d-flex align-items-center"><img width="30px" src="/assets/img/weather/wind.svg"
              alt="wind_icon" />{{ weather?.current.wind_mph }} m/s</li>
          <li class="d-flex align-items-center"><img width="30px" src="/assets/img/weather/thermometer-celsius.svg"
              alt="thermometer_icon" />{{ weather?.current.temp_c }} °C</li>
          <li class="d-flex align-items-center"><img width="30px" src="/assets/img/weather/raindrop.svg"
              alt="raindrop_icon" />{{ weather?.current.humidity }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-md-9" *ngIf="isHiddenDailyWeather">
    <div class="row">
      <ng-container *ngFor="let item of weather?.forecast.forecastday;">
        <div class="mx-2 col-sm-2 mb-3 mb-lg-0 d-flex flex-column align-items-center justify-content-between">
          <div class="headCard d-flex justify-content-center flex-column align-items-center">
            <h5>{{ getDayOfWeeks(item.date) }}</h5>
            <img src="{{ getWeatherIcon(item.day.condition.code) }}" width="70px" alt="Mostly Sunny" class="mb-2" />
            <p class="text-center">{{ item.day.condition.text }}</p>
          </div>
          <div class="">
            <div class="text-center text-danger">{{ item.day.maxtemp_c }}°C</div>
            <div class="text-center text-primary">{{ item.day.mintemp_c }}°C</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
