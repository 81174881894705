import { ComponentRef, ContentChild, Directive, inject } from '@angular/core';
import { DialogContent } from './dialog/dialog.content';
import { DIALOG } from './dialog/dialog.token';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { DialogOption } from './dialog/dialog.option';
import { DialogType } from './dialog/dialog.type';
import { DialogEmptyComponent, DialogInformationComponent, DialogStandardComponent } from './dialog';
import { Context } from '@core/application';

@Directive()
export abstract class ViewBase {
  /** Material Dialog */
  protected dialog: MatDialog;
  public dialogRef: MatDialogRef<any> | undefined;
  protected context: Context<any>;

  constructor() {
    /** Inject MatDialog */
    this.dialog = inject(MatDialog);
    const ctxServiceType = this.getCtxServiceType();
    this.context = inject(Context<typeof ctxServiceType>);
  }

  /**
   * Open new modal
   * @param modal -> component
   * @param fn -> callback function
   */
  open<T = DialogType, D = any>(modal: ComponentType<any>, config?: DialogOption<T, D>) {
    if (config) {
      config.dynamicComponent = modal;
      console.log('--->' + config.type);
      let dType: any = config.type; //? DialogType.STANDARD : config.type;
      this.dialogRef = this.dialog.open(this.dialogCompTypeRef(dType), {
        panelClass: ['modal', 'modal-no-backdrop'],
        data: {
          component: modal,
          data: config.data,
        },
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (config.cfn) {
          config.cfn(result);
        }
      });
    }
  }

  private dialogCompTypeRef(type: DialogType): any {
    let dialog: any = DialogEmptyComponent;
    console.log('TYPEYPE: ' + type);
    switch (type) {
      case DialogType.STANDARD:
        dialog = DialogStandardComponent;
        break;
      case DialogType.INFORMATION:
        dialog = DialogInformationComponent;
        break;
    }
    console.log('TYPEYPE: ' + type);
    return dialog;
  }

  setCxtEndPoint(endPoint: string): void {
    this.context.setEndPoint(endPoint);
  }
  abstract getCtxServiceType(): any;
}
