import { Injectable } from '@angular/core';
import { ServiceAbstract } from '../service.abstract';
import { SignInWriteableRepository } from './sign-in.writeable.repository';

@Injectable({ providedIn: 'root' })
export class SignInWriteableService extends ServiceAbstract<SignInWriteableRepository> {
  /**
   * Register
   * @param userName -> user name
   * @param password -> password
   * @returns -> success = token
   */
  register(userName: string, password: string): string {
    /** TODO */
    if (userName == undefined || userName == '') {
      return 'false';
    }
    if (password == undefined || password == '') {
      return 'false';
    }
    return this.repository.register(userName, password);
  }

  override repositoryType(): any {
    return SignInWriteableRepository;
  }
}
