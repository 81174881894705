import { Injectable } from '@angular/core';
import { ReadableRepository } from '../readable.repository';
import { SchedulerByCronjobListRequest, SchedulerByCronjobListResponse } from '@infrastructure/message';

@Injectable({ providedIn: 'root' })
export class SchedulerByCronjobReadableRepository extends ReadableRepository {

  findSchedulerCronjobs(endPoint: string, body: SchedulerByCronjobListRequest): Promise<SchedulerByCronjobListResponse> {
    return this.findData<SchedulerByCronjobListResponse>(endPoint, SchedulerByCronjobListResponse, body);
  }

}
