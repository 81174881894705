import { map } from 'rxjs/operators';
import { RepositoryBase } from './repository.base';
import { ResponseMapper, RequestMapper } from '@infrastructure/xhelper';

export class ReadableRepository extends RepositoryBase {
  /**
   * find item by id
   * @param id -> id (1, 2, 3, ...)
   * @param endPoint -> API name
   */
  findById<T>(endPoint: string, type: any, id: number): Promise<T> {
    return new Promise<T>((resolve) => {
      return this.get<any>(`${endPoint}/${id}`)
        .pipe<any>(map((data) => new ResponseMapper(type).map(data)))
        .subscribe((data: any) => {
          resolve(data.transform());
        });
    });
  }

  /**
   * @param endPoint -> API name
   * @param type -> message type
   * @param body -> UUID
   * @returns
   */
  findByUUID<T>(endPoint: string, type: any, uuid: string): Promise<T> {
    return new Promise<T>((resolve) => {
      return this.get<any>(`${endPoint}/${uuid}`)
        .pipe<any>(map((data) => new ResponseMapper(type).map(data)))
        .subscribe((data: any) => {
          resolve(data.transform());
        });
    });
  }

  /**
   * find all items
   * @param active -> if active = null , take all active and unactive
   * @param endPoint -> API name
   * @param type -> type of the response
   */
  findAll<T>(endPoint: string, type: any): Promise<T> {
    return new Promise<T>((resolve) => {
      return this.get<any>(endPoint)
        .pipe<any>(map((data) => new ResponseMapper(type).map(data)))
        .subscribe((data: any) => {
          resolve(data.transform());
        });
    });
  }

  findData<T>(endPoint: string, type: any, body: any): Promise<T> {
    return new Promise<T>((resolve) => {
      return this.post<any>(`${endPoint}`, body)
        .pipe<T>(map((data) => new ResponseMapper(type).map(data)))
        .subscribe((data: any) => {
          resolve(data);
        });
    });
  }

  /**
   * Fetch a file from the server with authentication
   * @param endPoint -> API endpoint
   * @returns Promise<Blob>
   */
  fetchFile(endPoint: string): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      const subscription = this.httpClient.get(endPoint, 
        { responseType: 'blob' }
      ).subscribe({
        next: (blob) => {
          resolve(blob as Blob);
        },
        error: (err) => {
          reject(err);
        }
      });
    })
  }

  findDataV2<T>(endPoint: string, requestType: any, responseType: any, body: any): Promise<T> {
    return new Promise<T>((resolve) => {
      return this.post<any>(`${endPoint}`, new RequestMapper(requestType).map(body))
        .pipe<T>(map((data) => new ResponseMapper(responseType).map(data)))
        .subscribe((data: any) => {
          resolve(data.transform());
        });
    });
  }
}
