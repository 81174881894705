export interface BaseContent {
  refresh(fnd?: any): void;
  setCxtEndPoint(endPoint: string): void;
}
// export interface ViewContent extends BaseContent {
//   getTitle(): string | undefined;
//   getBreadcrumb(): BreadcrumbItem[];
// }

export interface BreadcrumbItem {
  name: string;
  url: string;
  active: boolean;
}

/** Use the abstraction class as provider use */
export abstract class ViewXContent {
  //} implements ViewContent {
  abstract setCxtEndPoint(endPoint: string): void;
  abstract refresh(fnd?: any): void;
  abstract getTitle(): string | undefined;
  abstract getBreadcrumb(): BreadcrumbItem[];
}

export abstract class ViewXEContent {
  //implements ViewEContent {
  abstract setCxtEndPoint(endPoint: string): void;
  abstract refresh(fnd?: any): void;
}
