import { ChangeDetectorRef, Component, ComponentRef, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cell-dialog-information',
  standalone: true,
  imports: [],
  templateUrl: './dialog-information.component.html',
  styleUrl: './dialog-information.component.scss',
})
export class DialogInformationComponent implements OnInit {
  /**
   * Retrieve the dynamic content via DI of dialog content
   */
  @ViewChild('containerRef', { read: ViewContainerRef, static: true })
  container!: ViewContainerRef;

  /**
   * Component is a dialog component
   * which will display dynamic in runtime.
   */
  componentRef!: ComponentRef<any>;

  constructor(private dialogRef: MatDialogRef<DialogInformationComponent>, private cdref: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) public data: { component: any; data: any }) {}

  ngOnInit(): void {
    this.render();
  }
  /**
   * Render the view(layout) dymamically.
   */
  protected render(): void {
    this.container.clear();
    this.componentRef = this.container.createComponent(this.data.component);
  }

  close() {
    this.dialogRef.close();
  }
}
