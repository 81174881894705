import { Component, ComponentRef, Inject, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { NAVIGATION_COMPONENT } from '../../navigation.content.token';
import { NavigationBase } from '../../navigation.base';

@Component({
  selector: 'cell-navigation-standard',
  standalone: true,
  imports: [],
  templateUrl: './navigation-standard.component.html',
  styleUrl: './navigation-standard.component.scss',
})
export class NavigationStandardComponent implements OnInit {
  /**
   * Retrieve the dynamic content via DI of dialog content
   */
  @ViewChild('containerRef', { read: ViewContainerRef, static: true })
  container!: ViewContainerRef;

  /**
   * Component is a dialog component
   * which will display dynamic in runtime.
   */
  componentRef!: ComponentRef<any>;

  constructor(@Inject(NAVIGATION_COMPONENT) public component: Type<NavigationBase>) {
    /** Check the component type is inherited NavigationBase */
    const componentPrototype = Object.getPrototypeOf(component.prototype);
    if (componentPrototype !== NavigationBase.prototype) {
      throw new Error('The component type is not NavigationBase.');
    }
  }

  ngOnInit(): void {
    this.render();
  }
  /**
   * Render the view(layout) dymamically.
   */
  protected render(): void {
    this.container.clear();
    this.componentRef = this.container.createComponent(this.component);
  }
}
