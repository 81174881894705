import moment from 'moment';

export class XMHelper {
  /**
   * Returns the full name of the day of the week for a given date.
   *
   * @param date - The date to be checked. It can be in any format that Moment.js can parse.
   * @returns The full name of the day of the week (e.g., 'Monday', 'Tuesday').
   */
  public static getDayOfWeeks(date: any): String {
    return moment(date).format('dddd');
  }

  /**
   * Checks if the given date is today.
   *
   * @param date - The date to be checked. It can be in any format that Moment.js can parse.
   * @returns A boolean indicating whether the given date is today.
   */
  public static isToDay(date: any): boolean {
    return moment().isSame(moment(date), 'day');
  }
}
