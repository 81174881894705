import { propertyMap } from '@infrastructure/xhelper';
import { BOBase } from '../bo.base';
import { CronTimeBO } from '../cron-time.bo';

export class CronjobClientBO extends BOBase {
  @propertyMap('id', Number)
  id: number = 0;

  @propertyMap('name', String)
  name: string = '';

  @propertyMap('action', String)
  action: string = '';

  @propertyMap('status', Number)
  status: number = 0;

  @propertyMap('type', Number)
  type: number = 0;

  @propertyMap('time', CronTimeBO)
  time: CronTimeBO = new CronTimeBO();

}
