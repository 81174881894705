import { SignInBO } from '@core/domain';
import { ServiceAbstract } from '../service.abstract';
import { SignInReadableRepository } from './sign-in.readable.repository';
import { Injectable } from '@angular/core';
import { RefreshTokenResponse, SignInRequest } from '@infrastructure/message';

@Injectable({ providedIn: 'root' })
export class SignInReadableService extends ServiceAbstract<SignInReadableRepository> {

  /**
   * Sign In
   * @param email -> email
   * @param password -> password
   * @returns -> success = true; unsuccess = false
   */
  async signIn(email: string, password: string, appName: string, isRemember: false): Promise<SignInBO> {
    const body: SignInRequest = {
      email,
      password,
      appName,
      isRemember,
    };

    const endpoint = appName ? `${this._endPoint}/cell/authentication/sign-in` : `${this._endPoint}/authentication/sign-in`;

    const response = await this.repository.signIn(endpoint, body);
    return response;
  }

  async refreshToken(refreshToken: string): Promise<RefreshTokenResponse> {
    let body = {
      rto: refreshToken

    }
    let response = await this.repository.refreshToken(this._endPoint + '/authentication/refresh', body);

    return response;
  }

  override repositoryType(): any {
    return SignInReadableRepository;
  }
}
