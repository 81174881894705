import { propertyMap } from '@infrastructure/xhelper';
import { BOBase } from './bo.base';

export class NavigationBO extends BOBase {
  @propertyMap('id', Number)
  id?: number = 0;

  @propertyMap('name', String)
  name?: string = '';

  @propertyMap('path', String)
  path?: string = '';

  @propertyMap('icon', String)
  icon?: string = '';

  @propertyMap('description', String)
  description?: string = '';

  @propertyMap('type', Number)
  type?: number = 0;

  @propertyMap('active', Number)
  active?: number = 0;

  @propertyMap('is_divider', Number)
  isDivider?: boolean = false;

  @propertyMap('is_header', Number)
  isHeader?: boolean = false;

  @propertyMap('created_at', String)
  createdAt?: string = '';

  @propertyMap('created_by', String)
  createdBy?: string = '';

  @propertyMap('children', NavigationBO)
  children?: NavigationBO[] = [];

}
