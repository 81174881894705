import { Inject, Injectable, inject } from '@angular/core';
import { ServiceAbstract } from '../service.abstract';
import { DispatcherReadableRepository } from './dispatcher.readable.repository';
import { NotificationReadableService } from '../notification';
import { Constant } from '@infrastructure/xhelper';
import { ModuleReadableService } from '../module';

@Injectable({ providedIn: 'root' })
export class DispatcherReadableService extends ServiceAbstract<DispatcherReadableRepository> {
  notificationReadableService: NotificationReadableService | undefined;
  moduleReadableService: ModuleReadableService | undefined;

  constructor(@Inject(Constant.ENVIRONMENT) private environment: any) {
    super();
    this.notificationReadableService = inject(NotificationReadableService);
    this.notificationReadableService.endPoint = this.environment.apiUrl;

    this.moduleReadableService = inject(ModuleReadableService);
    this.moduleReadableService.endPoint = this.environment.apiUrl;
  }

  override repositoryType(): any {
    return DispatcherReadableRepository;
  }
}
