import { inject, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ServiceAbstract } from './service.abstract';
import { ReadableRepository } from './readable.repository';
import { BrowserStorageService } from './browser-storage.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class Context<T> {
  /** Keep storage */
  storage: BrowserStorageService | undefined;

  constructor(public serviceAbtract: ServiceAbstract<ReadableRepository>, @Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.storage = inject(BrowserStorageService);
    }
  }

  setEndPoint(endPoint: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.serviceAbtract.endPoint = endPoint;
    }
  }

  getEndPoint(): string {
    let endPoint = '';
    if (isPlatformBrowser(this.platformId)) {
      endPoint = this.serviceAbtract.endPoint;
    }
    return endPoint;
  }

  /**
   * Set item to local storage
   * @param key
   * @param value
   */
  setItemStorage(key: string, value: string) {
    this.storage?.set(key, value);
  }

  /**
   * Get item from local storage
   * @param key
   * @returns
   */
  getItemStorage(key: string): string {
    return this.storage?.get(key);
  }

  /**
   * Get the real derieved Context
   */
  get service(): T {
    return this.serviceAbtract as T;
  }
}
