 import { propertyMap } from "@infrastructure/xhelper";
import { PagingRequestV2 } from "../paging-v2.request";

export class NotificationListRequest extends PagingRequestV2 {
  @propertyMap('from_user_id')
  fromUserId: number[] = [];

  @propertyMap('to_user_id')
  toUserId: number[] = [];
}
