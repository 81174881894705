import { propertyMap } from '@infrastructure/xhelper';
import { XResponse } from '../x.response';
import { SchedulerByClientListPagingResponse } from './scheduler-by-client-list-paging.response';

export class SchedulerByClientListResponse extends XResponse {
  @propertyMap('data', SchedulerByClientListPagingResponse)
  schedulerByClientListResponse: SchedulerByClientListPagingResponse = new SchedulerByClientListPagingResponse();

  override transform(data?: any): any {
    return undefined;
  }
}
